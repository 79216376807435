import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  tranxDetail: [],
  regularSpendCategories: {
    INCOME: [],
    LOAN_REPAYMENTS: [],
    GROCERIES: [],
  },
  regularSpendCategoriesReqUid: null,
  cashFlowData: {
    inflow: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
    },
    outflow: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
    },
  },
  transactionsGraphDataReqUid: null,
  identity: null,
};

export const bfmSlice = createSlice({
  name: "bfm",
  initialState,
  reducers: {
    tranxDetail: (state = initialState, { payload }) => {
      return {
        ...state,
        tranxDetail: payload,
      };
    },
    getRegularSpendCategories: (state = initialState, { payload }) => {
      return {
        ...state,
        regularSpendCategories: payload.data,
        regularSpendCategoriesReqUid: payload.uid,
      };
    },
    getCashFlowData: (state = initialState, { payload }) => {
      return {
        ...state,
        cashFlowData: payload.data,
      };
    },
    getTransactionsGraphData: (state = initialState, { payload }) => {
      return {
        ...state,
        transactionsGraphData: payload.data,
        transactionsGraphDataReqUid: payload.uid,
      };
    },
    getIdentity: (state = initialState, { payload }) => {
      return {
        ...state,
        identity: payload.data,
      };
    },
  },
  extraReducers: {},
});
