import { io } from "socket.io-client";

const SOCKET_URL = "ws://inflow-api.development.limited";

const token = localStorage.getItem("accessToken");

const socket = io(SOCKET_URL, {
  transports: ["websocket"],
  auth: {
    token: token,
  },
  withCredentials: true,
});

export default socket;
