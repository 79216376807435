import { createSlice } from "@reduxjs/toolkit";
const initialState = { transactions: null, transactionReceipt:null };

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setTransactions: (state = initialState, { payload }) => {
      return {
        ...state,
        transactions: payload,
      };
    },
    setTransactionReceipt: (state = initialState, { payload }) => {
      return {
        ...state,
        transactionReceipt: payload,
      };
    },
  },
  extraReducers: {},
});
