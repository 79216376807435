import { createAction } from "redux-actions";

export const LOGIN = "LOGIN";
export const login = createAction(LOGIN);

export const COMPANY_INFO = "COMPANY_INFO";
export const companyInfo = createAction(COMPANY_INFO);

export const REGISTER = "REGISTER";
export const register = createAction(REGISTER);

export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const verifyEmail = createAction(VERIFY_EMAIL);

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const forgotPassword = createAction(FORGOT_PASSWORD);

export const RESET_PASSWORD = "RESET_PASSWORD";
export const resetPassword = createAction(RESET_PASSWORD);

// start
export const RESET_PASSWORD_LINK = "RESET_PASSWORD_LINK";
export const resetPasswordLink = createAction(RESET_PASSWORD_LINK);

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const updatePassword = createAction(UPDATE_PASSWORD);
// end

export const SET_AUTHENTICATION_TOKEN = "SET_AUTHENTICATION_TOKEN";
export const setAuthenticationToken = createAction(SET_AUTHENTICATION_TOKEN);

export const CARD_ALTERATION_REQUEST = "CARD_ALTERATION_REQUEST";
export const cardAlterationRequest = createAction(CARD_ALTERATION_REQUEST);

export const UPDATE_CARD_ALTERATION_REQUEST = "UPDATE_CARD_ALTERATION_REQUEST";
export const updateCardAlterationRequest = createAction(UPDATE_CARD_ALTERATION_REQUEST);

export const LOGOUT = "LOGOUT";
export const logout = createAction(LOGOUT);
