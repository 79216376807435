import { all, put, takeLatest } from "redux-saga/effects";
import { notificationsSlice } from "../slices/notifications";
import {
  GET_NOTIFICATIONS,
  MARK_AS_READ,
  DELETE_NOTIFICATION,
} from "../actions/notifications.actions";

import httpClient from "../services/http.client";

import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* getNotifications({
  payload: { data, callback, is_read, page, size },
}) {
  let query = "";
  if (is_read) query += `is_read=${is_read}`;
  if (page) query += `page=${page}`;
  if (size) query += `size=${size}`;
  const payload = {
    data,
    method: "get",
    url: `/notifications/owner?${query}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    yield put(notificationsSlice.actions.setNotifications(result.data));
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* markAsRead({ payload: { data, callback, id } }) {
  const payload = {
    data,
    method: "patch",
    url: `/notifications/owner/${id}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* deleteNotification({ payload: { data, callback, id } }) {
  const payload = {
    data,
    method: "delete",
    url: `/notifications/owner/${id}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* Employer() {
  yield all([takeLatest(GET_NOTIFICATIONS, getNotifications)]);
  yield all([takeLatest(MARK_AS_READ, markAsRead)]);
  yield all([takeLatest(DELETE_NOTIFICATION, deleteNotification)]);
}

export default Employer;
