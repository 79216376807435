import { all, put, takeLatest } from "redux-saga/effects";
import { employerSlice } from "../slices/employer";
import {
  ADD_EMPLOYEE,
  EMPLOYEE_LIST,
  RESEND_INVITATION,
  ACCEPT_INVITATION,
  DELETE_EMPLOYEE,
  EMPLOYEE_ASSIGNED_CARDS,
  UPDATE_EMPLOYEE
} from "../actions/employer.actions.types";

import httpClient from "../services/http.client";

import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* addEmployee({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "put",
    url: "/company/add-employee",
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* resendInvitation({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "put",
    url: "/company/add-employee",
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* acceptInvitation({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "put",
    url: "/user/accept-invitation",
  };
  const { error, result } = yield call(httpClient, {
    payload,
    authorization: false,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* employeeList({ payload: { data, callback } }) {
  const payload = {
    method: "get",
    url: `/company/list-employees?from_date=${data?.from_date || ""}&to_date=${
      data?.to_date || ""
    }`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      yield put(employerSlice.actions.employerList(result.data));
      callback({ success: true, data: result.data });
    }
  }
}

function* deleteEmployee({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "delete",
    url: `/company/employees/${data.employeeId}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      yield put(employerSlice.actions.deleteEmployee(result.data));
      callback({ success: true, data: result.data });
    }
  }
}

function* employeeAssignedCards({ payload: { customerId, callback } }) {
  const payload = {
    method: "get",
    url: `/paytabs/all-cards?id=${customerId}&idType=CUSTOMER&card_status=CARD_ACTIVE`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* updateEmployee({ payload: { data, id, callback } }) {
  const payload = {
    data,
    method: "patch",
    url: `/company/employee/${id}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* Employer() {
  yield all([takeLatest(ADD_EMPLOYEE, addEmployee)]);
  yield all([takeLatest(RESEND_INVITATION, resendInvitation)]);
  yield all([takeLatest(ACCEPT_INVITATION, acceptInvitation)]);
  yield all([takeLatest(EMPLOYEE_LIST, employeeList)]);
  yield all([takeLatest(DELETE_EMPLOYEE, deleteEmployee)]);
  yield all([takeLatest(EMPLOYEE_ASSIGNED_CARDS, employeeAssignedCards)]);
  yield all([takeLatest(UPDATE_EMPLOYEE, updateEmployee)]);
}

export default Employer;
