import { createAction } from "redux-actions";

export const GET_USER_PREFERENCE = "GET_USER_PREFERENCE";
export const getUserPreference = createAction(GET_USER_PREFERENCE);

export const UPDATE_USER_PREFERENCE = "UPDATE_USER_PREFERENCE";
export const updateUserPreference = createAction(UPDATE_USER_PREFERENCE);

export const UPLOAD_PROFILE_PICTURE = "UPLOAD_PROFILE_PICTURE";
export const uploadProfilePicture = createAction(UPLOAD_PROFILE_PICTURE);

export const GET_PROFILE_PICTURE = "GET_PROFILE_PICTURE";
export const getProfilePicture = createAction(GET_PROFILE_PICTURE);
