import { all } from "redux-saga/effects";
import app from "./app";
import user from "./user";
import business from "./business";
import employer from "./employer";
import bfm from "./bfm";
import payments from "./payments";
import Anb from "./anb";
import setting from "./setting";
import paytabs from "./paytabs";
import transaction from "./transaction";
import notifications from "./notifications";

import subscription from "./subscriptions";
import Cards from "./cards";
const sagas = function* sagas() {
  yield all([
    app(),
    user(),
    business(),
    employer(),
    bfm(),
    payments(),
    subscription(),
    Anb(),
    Cards(),
    setting(),
    paytabs(),
    transaction(),
    notifications(),
  ]);
};

export default sagas;
