import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: null,
  token: null,
  email: "",
  requests: null,
};

export const userslice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuthenticationToken: (state = initialState, { payload }) => {
      return {
        ...state,
        token: payload,
      };
    },
    loginAction: (state = initialState, { payload }) => {
      return {
        ...state,
        userDetails: payload,
      };
    },
    logoutAction: (state = initialState) => {
      return {
        ...state,
        token: null,
        userDetails: null,
      };
    },
    setProfilePicture: (state = initialState, { payload }) => {
      return {
        ...state,
        userDetails: { ...state.userDetails, profilePicture: payload },
      };
    },
    resetPasswordLink: (state) => state,
    createNewPassword: (state) => state,
    udpatePassword: (state) => state,
    setEmail: (state = initialState, { payload }) => {
      return {
        ...state,
        email: payload,
      };
    },
    setRequests: (state = initialState, { payload }) => {
      return {
        ...state,
        requests: payload,
      };
    },
  },
});
