import { all, takeLatest, put, takeEvery } from "redux-saga/effects";
import {
  GET_USER_PREFERENCE,
  UPDATE_USER_PREFERENCE,
  UPLOAD_PROFILE_PICTURE,
  GET_PROFILE_PICTURE,
} from "../actions/setting.actions.types";

import httpClient from "../services/http.client";

import * as Effects from "redux-saga/effects";
import { successMessage } from "../../utilities/notification";
import { userslice } from "../slices/user";
const call = Effects.call;

function* getUserPreference({ payload: { callback } }) {
  const payload = {
    method: "get",
    url: "/preferences/user",
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* updateUserPreference({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: "/preferences/user",
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* uploadProfilePicture({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: "/user/profile-picture",
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    successMessage(result.message);
    yield put(userslice.actions.setProfilePicture(result?.data?.path));
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* getProfilePicture({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "get",
    url: "/user/profile-picture",
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    yield put(userslice.actions.setProfilePicture(result?.data?.path));
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* Cards() {
  yield all([
    takeLatest(GET_USER_PREFERENCE, getUserPreference),
    takeLatest(UPDATE_USER_PREFERENCE, updateUserPreference),
    takeLatest(UPLOAD_PROFILE_PICTURE, uploadProfilePicture),
    takeEvery(GET_PROFILE_PICTURE, getProfilePicture),
  ]);
}
export default Cards;
