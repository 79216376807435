import { createSlice } from "@reduxjs/toolkit";
const initialState = { notificationsList: [] };

export const notificationsSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotifications: (state = initialState, { payload }) => {
      return {
        ...state,
        notificationsList: payload,
      };
    },
  },
  extraReducers: {},
});
