import { createAction } from "redux-actions";

export const FETCH_CARDS = "FETCH_CARDS";
export const fetchCards = createAction(FETCH_CARDS);

export const ORDER_PHYSICAL_CARD = "ORDER_PHYSICAL_CARD";
export const orderPhysicalCard = createAction(ORDER_PHYSICAL_CARD);

export const ORDER_VIRTUAL_CARD = "ORDER_VIRTUAL_CARD";
export const orderVirtualCard = createAction(ORDER_VIRTUAL_CARD);

export const GET_CARD_PREFERENCES = "GET_CARD_PREFERENCES";
export const getCardPreferences = createAction(GET_CARD_PREFERENCES);

export const SET_CARD_PREFERENCES = "SET_CARD_PREFERENCES";
export const setCardPreferences = createAction(SET_CARD_PREFERENCES);

export const SET_CARD_STATUS = "SET_CARD_STATUS";
export const setCardStatus = createAction(SET_CARD_STATUS);

export const GET_BALANCE = "GET_BALANCE";
export const getBalance = createAction(GET_BALANCE);

export const GET_ACCOUNT = "GET_ACCOUNT";
export const getAccount = createAction(GET_ACCOUNT);

export const FUND_TRANSFER = "FUND_TRANSFER";
export const fundTransfer = createAction(FUND_TRANSFER);
