import { createAction } from "redux-actions";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const getNotifications = createAction(GET_NOTIFICATIONS);

export const MARK_AS_READ = "MARK_AS_READ";
export const markAsRead = createAction(MARK_AS_READ);

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const deleteNotification = createAction(DELETE_NOTIFICATION);
